html {
  height: 100%;
  overflow: hidden;
}

body {
  --size: 30vmin;
  --angle: 0deg;
  background-color: #00f;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 300%;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.shine {
  background-image: url("burst.de691629.svg");
}

#ten {
  flex: 0 1 var(--size);
  width: var(--size);
  height: var(--size);
  background-image: conic-gradient(from var(--angle) at 50% 50%, #00f, #000);
  box-sizing: border-box;
  z-index: 200;
}

#ten:hover {
  cursor: pointer;
  box-shadow: 0 0 1px #fff, 0 0 8px 4px #fff4, 0 0 12px 6px #fff4, 0 0 24px 12px #fff4;
}

svg {
  height: var(--size);
  flex: 0 0 var(--size);
  width: 100%;
}

.clickable:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 2px #fff9) drop-shadow(0 0 8px #fffc);
}

svg.pclogo {
  margin-left: auto;
  position: relative;
  right: 1.5%;
}

svg.pc10 {
  margin-right: auto;
}

svg path {
  fill: #fff;
}

.text-wrapper {
  color: #fff;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.left.inner {
  --p: 20px;
  margin: var(--p) calc(var(--p)  + 50%) var(--p) var(--p);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

iframe {
  bottom: 20px;
  bottom: max(env(safe-area-inset-bottom), 20px);
  position: fixed;
  right: 20px;
}

@media (width < 500px) {
  iframe {
    width: calc(100% - 40px);
  }
}

::selection {
  color: #00f;
  background: #fff;
}

.overlay {
  -webkit-mask-image: conic-gradient(from calc(-1 * var(--angle)) at 50% 50%, #fff, #fff0 45deg, #fff0);
  mask-image: conic-gradient(from calc(-1 * var(--angle)) at 50% 50%, #fff, #fff0 45deg, #fff0);
  z-index: 100;
  pointer-events: none;
  background: radial-gradient(circle closest-side, #00f 35%, #00f0 50%), #00f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(-1);
}

dialog {
  color: #fff;
  z-index: 300;
  background: #00fe;
  border: 1px solid;
  border-radius: 6px;
  width: 100%;
  max-width: 256px;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: auto;
}

dialog form {
  border-bottom: 1px solid #fff;
  display: flex;
}

dialog button {
  font: inherit;
  text-transform: uppercase;
  color: inherit;
  background: none;
  border: none;
  margin: 0 0 0 auto;
  padding: 12px 16px;
  font-size: 10px;
  line-height: 12px;
}

dialog button:focus {
  outline: none;
}

dialog button:focus-visible {
  color: #00f;
  background: #fff;
  outline: none;
}

dialog form button:focus-visible {
  border-top-right-radius: 4px;
}

dialog .contents {
  box-sizing: border-box;
  scrollbar-color: #fff #00f;
  width: 100%;
  max-height: 256px;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 1.5;
  overflow: auto;
}

dialog .contents.shine {
  background-color: #44f;
  background-position: center;
  background-size: 1300px;
  border-radius: 0 0 6px 6px;
}

dialog h2 {
  margin: 0;
  padding: 12px;
  font-size: 12px;
  line-height: 1;
}

dialog h3 {
  margin: 0;
  padding: 12px 0;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

dialog ol {
  margin: 0 0 12px;
  padding-left: 28px;
}

dialog details summary {
  position: relative;
}

dialog details.np summary:after {
  content: "←  Now Playing";
  padding: 12px 0;
  line-height: 1;
  position: absolute;
  right: 0;
}

::backdrop {
  background: red;
}

#shine {
  max-width: 160px;
}

#shine .toggle {
  margin: 0 auto;
  display: block;
  position: relative;
}

#shine .toggle:after {
  content: "PC";
  letter-spacing: .05em;
  font-size: 60%;
  line-height: 12px;
  position: absolute;
  top: 1em;
}

/*# sourceMappingURL=index.a1193fda.css.map */
