html {
  height: 100%;
  overflow: hidden;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  background-color: #00f;
  --size: 30vmin;
  --angle: 0deg;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 300%;
}
.shine {
  background-image: url(../burst.svg);
}
#ten {
  flex: 0 1 var(--size);
  width: var(--size);
  height: var(--size);
  background-image: conic-gradient(from var(--angle) at 50% 50%, #00f, #000);
  box-sizing: border-box;
  z-index: 200;
}
#ten:hover {
  box-shadow: 0 0 1px #fff, 0 0 8px 4px #fff4, 0 0 12px 6px #fff4,
    0 0 24px 12px #fff4;
  cursor: pointer;
}
svg {
  height: var(--size);
  flex: 0 0 var(--size);
  width: 100%;
}
.clickable:hover {
  cursor: pointer;
  filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.6))
    drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
}
svg.pclogo {
  margin-left: auto;
  right: 1.5%;
  position: relative;
}
svg.pc10 {
  margin-right: auto;
}
svg path {
  fill: #fff;
}
.text-wrapper {
  margin: 0;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}
.left.inner {
  position: absolute;
  height: 100%;
  --p: 20px;
  margin: var(--p) calc(var(--p) + 50%) var(--p) var(--p);
  top: 0;
  left: 0;
}
iframe {
  position: fixed;
  bottom: 20px;
  bottom: max(env(safe-area-inset-bottom), 20px);
  right: 20px;
}

@media (width < 500px) {
  iframe {
    width: calc(100% - 40px);
  }
}

::selection {
  background: rgba(255, 255, 255, 0.9999);
  color: #00f;
}

.overlay {
  mask-image: conic-gradient(
    from calc(-1 * var(--angle)) at 50% 50%,
    rgba(255, 255, 255, 1),
    /* rgba(255, 255, 255, 0.9) 0.2deg,
    rgba(255, 255, 255, 0) 8deg, */
      #ffffff00 45deg,
    #ffffff00
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  background: radial-gradient(
      circle closest-side at center,
      #00f 35%,
      #00f0 50%
    ),
    #00f;

  transform: scaleX(-1);
}

dialog {
  position: absolute;
  padding: 0;
  background: #00fe;
  border-radius: 6px;
  color: #fff;
  border: 1px solid;
  z-index: 300;
  margin: 0;
  width: 100%;
  max-width: 256px;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
}
dialog form {
  border-bottom: 1px solid #fff;
  display: flex;
}
dialog button {
  background: none;
  border: none;
  font: inherit;
  font-size: 10px;
  text-transform: uppercase;
  padding: 12px 16px;
  color: inherit;
  margin: 0 0 0 auto;
  line-height: 12px;
}

dialog button:focus {
  outline: none;
}
dialog button:focus-visible {
  outline: none;
  background: #fff;
  color: #00f;
}
dialog form button:focus-visible {
  border-top-right-radius: 4px;
}
dialog .contents {
  font-size: 12px;
  line-height: 1.5;
  padding: 8px 12px;
  box-sizing: border-box;
  max-height: 256px;
  overflow: auto;
  width: 100%;
  scrollbar-color: #fff #00f;
}
dialog .contents.shine {
  background-position: center;
  background-size: 1300px;
  border-radius: 0 0 6px 6px;
  background-color: #44f;
}
dialog h2 {
  font-size: 12px;
  line-height: 1;
  padding: 12px;
  margin: 0;
}
dialog h3 {
  font-size: 12px;
  line-height: 1;
  padding: 12px 0;
  margin: 0;
  display: inline-block;
}
dialog ol {
  padding-left: 28px;
  margin: 0 0 12px 0;
}
dialog details summary {
  position: relative;
}
dialog details.np summary::after {
  content: "\2190\00A0\00A0 Now Playing";
  position: absolute;
  right: 0;
  padding: 12px 0;
  line-height: 1;
}
::backdrop {
  background: red;
}
#shine {
  max-width: 160px;
}
#shine .toggle {
  margin: 0 auto;
  display: block;
  position: relative;
}
#shine .toggle::after {
  content: "PC";
  font-size: 60%;
  letter-spacing: 0.05em;
  position: absolute;
  top: 1em;
  line-height: 12px;
}
